import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import logo from '../../assets/maxor_plus.svg';
import { useTheme } from '@mui/material/styles';

const Header = () => {
  const theme = useTheme();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ bgcolor: theme.palette.background.paper }}>
        <Toolbar sx={{ borderBottom: 8, borderColor: theme.palette.secondary.main, pt:2, pb: 1 }}>
          <img src={logo} className="App-logo" alt="logo" />
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;