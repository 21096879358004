import { Box, useTheme } from "@mui/material";

const Footer = () => {
    const theme = useTheme();

    return (
        <Box sx={{ p:2,
                flexGrow: 1,
                bgcolor: theme.palette.primary.main,
                color: 'white'
            }}>
            <Box>© 2024 Maxor National Pharmacy Services, LLC. All Rights Reserved.</Box>
            <Box sx={{ pt: 1 }}>
                <a className="footer-link" href="https://www.maxor.com/website-hipaa-notice-privacy-practices/" target="_blank" rel="noreferrer">
                    HIPAA Notice of Privacy Practices
                </a>
            </Box>
            <Box>
                <a className="footer-link" href="https://www.maxor.com/website-privacy-policy/" target="_blank" rel="noreferrer">
                    Website Privacy Policy
                </a>
            </Box>
        </Box>
    )
}

export default Footer;