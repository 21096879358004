import '../../styles/Pharmacy.css';
import { Card, CardActions, CardContent } from "@mui/material";
import { Pharmacy } from "../../types/Pharmacy";
import PhoneButton from '../common/PhoneButton';
import DirectionsButton from '../common/DirectionsButton';

interface PharmacyCardProps {
  pharmacy: Pharmacy;
  onCardClick: (pharmacyId: number) => void;
  isSelected: boolean;
}

const PharmacyCard = ( {pharmacy, onCardClick, isSelected} : PharmacyCardProps ) => {

  const address = pharmacy.PharmAddr1 + (pharmacy.PharmAddr2??" ") +
    pharmacy.PharmCity + ", " + pharmacy.PharmState + " " + pharmacy.PharmZip;

  const handleClick = () => {
    console.log('Pharm card clicked: ', pharmacy);
    onCardClick(pharmacy.id);
  };

  return (
    <Card sx={{m: 1}} 
      onClick={handleClick}
      variant='outlined'
      className={`pharmacy-card ${isSelected ? 'selected' : ''}`}>
      <CardContent sx={{p: 1, pb: 0}}>
        <div className="pharmacy-address-container">
          <div className='pharmacy-title-container'>
            <div className='pharmacy-title'>
              <h4>{pharmacy.id}</h4>
              <h3>{pharmacy.PharmacyName}</h3>
            </div>
            <span>{pharmacy.DistanceFromAddress} mi</span>
          </div>
          <div className='pharmacy-address'>
            <p>{pharmacy.PharmAddr1}</p>
            <p>{pharmacy.PharmAddr2}</p>
            <p>{pharmacy.PharmCity}, {pharmacy.PharmState} {pharmacy.PharmZip}</p>
          </div>
        </div>
      </CardContent>
      <CardActions>
        <PhoneButton phoneNumber={pharmacy.Phone}/>
        <DirectionsButton
          mapUrl={pharmacy.MapUrl}
          address={address}
        />
      </CardActions>
    </Card>
  );
}

export default PharmacyCard;
