import PharmacyList from '../pharmacy/PharmacyList';
import { Pharmacies } from "../../types/Pharmacy";
import { Box, Button, Grid } from '@mui/material';
import PharmacyMap from '../pharmacy-map/PharmacyMap';
import { useState } from 'react';

interface PharmacySearchResultsProps {
  results: Pharmacies;
}

const PharmacySearchResults = ( {results} : PharmacySearchResultsProps ) => {
  const [displayCount, setdisplayCount] = useState(10);
  const [selectedPharmacy, setSelectedPharmacy] = useState(-1);

  const pharmaciesToDisplay = results.slice(0, displayCount);

  const handlePharmacySelect = (pharmacyId: number) => {
    console.log('(in Results) setting selected pharmacy to: ' + pharmacyId);
    setSelectedPharmacy(pharmacyId);
  }

  const handleShowMore = () => {
    setdisplayCount(displayCount + 10);
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6} sx={{ m: 'auto' }}>
        <Box>Showing {pharmaciesToDisplay.length} of {results.length} Results</Box>
        <Box sx={{overflow: 'auto', maxHeight: '70vh'}}>
          <PharmacyList 
            pharmacies={ pharmaciesToDisplay }
            onPharmacySelect={handlePharmacySelect}
            selectedPharmacy={selectedPharmacy}
          />
          <Button
            onClick={handleShowMore}
            disabled={pharmaciesToDisplay.length === results.length ? true : false}
            variant='contained'
            sx={{minWidth: '60%', mb: 2}}
          >
            Show More
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <PharmacyMap
          pharmacies={ pharmaciesToDisplay }
          selectedPharmacy={ selectedPharmacy }
        />
      </Grid>
      <Box sx={{p:1, pt:2, fontStyle: 'italic', m: 'auto', pl: 2}}>
        Please note that health plans may choose to exclude certain pharmacies from coverage.
      </Box>
    </Grid>
  );
}

export default PharmacySearchResults;
