import { useState } from 'react';
import PharmacySearchForm from './PharmacySearchForm';
import PharmacySearchResults from './PharmacySearchResults';
import { Alert, Box, CircularProgress, Grid, Snackbar } from '@mui/material';
import { Pharmacies, Pharmacy } from "../../types/Pharmacy";
import { IPharmacySearchParameters } from '../../types/IPharmacySearchParameters';
import { useParams } from 'react-router-dom';
import { trackGA4Event } from '../../utils/google-analytics/ga4';

const PharmacySearch = () => {
  const [pharmaciesReturned, setpharmaciesReturned] = useState<Pharmacies>([]);
  const [isLoading, setIsLoading] = useState(false); // TODO this should probably be moved into a service?
  const [alertMsg, setAlertMsg] = useState('');
  const [openAlert, setOpenAlert] = useState(false);

  // TODO figure out how to only call this once and store in state..?
  let { carrier } = useParams();
  console.log("Carrier from URL parameters is: ", carrier);

  const handleSearch = async (params: IPharmacySearchParameters) => {
    console.log('Handling Search in PharmacySearch - using parameters: ', params);
    setpharmaciesReturned( [] );
    setIsLoading( true );
    trackGA4Event(
      "pharmacy_search",
      "search_submitted",
      window.location.hash,
      params
    );
    
    try {
      // TODO move this logic into a data service (or helper function? whatever react calls the pattern)
      const urlPrefix = process.env.REACT_APP_EXT_SVC_BASE_URL;
      const raw = await fetch(urlPrefix + '/LakerApi/GetInNetworkPharmaciesByRadius', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Radius: params.radius,
          Zipcode: params.zipCode,
          Subscribernum: params.plan,
          UserAddress: {
            Address: '',
            City: '',
            State: null,
            Zipcode: params.zipCode
          }
        })
      });
      const data = await raw.json();
      console.log("data: ", data);
      setIsLoading(false);
      if( data.Data.length === 0 ) {
        setAlertMsg('No pharmacies were found near the zip code you have entered, or the zip code is invalid. Please try again.');
        setOpenAlert(true);
      } else {
        let index = 1;
        const pharmacies = data.Data.map( (pharmacy:Pharmacy) => ({...pharmacy, id:index++}) ); // adding index to each pharmacy
        setpharmaciesReturned( pharmacies );
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setAlertMsg('We encountered a problem getting pharmacy information. Please contact MaxorPlus Customer Service at (800) 687-0707.');
      setOpenAlert(true);
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') { return; } 
    setOpenAlert(false);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ m: 'auto' }}>
          <PharmacySearchForm onSearchClick={ handleSearch } />
          <Snackbar open={openAlert} onClose={handleClose} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert variant="filled" severity="error">
              { alertMsg }
            </Alert>
          </Snackbar>
        </Grid>
        <Grid item xs={12}>
          { isLoading ? <Box sx={{ pt: 6 }}><CircularProgress size={80}/></Box> :
              !isLoading && pharmaciesReturned.length > 0 ?
                <PharmacySearchResults results={pharmaciesReturned} /> : null // TODO replace null with error message??
          }
        </Grid>
      </Grid>
    </>
  );
};

export default PharmacySearch;