import { Box } from "@mui/material";

const PharmacyMapMessage = () => {
  // TODO: add support for cusotm messages to be loaded/displayed
  return (
    <Box sx={{p:1, pt:2, fontStyle: 'italic', m: 'auto'}}>
      You may use the phone number or email address below to notify MaxorPlus of inaccurate directory information.
      <br /><br />
      <a href="tel:+18069055266">806-905-5266</a>
      <br />
      <a href="mailto:providerrelations@maxor.com">providerrelations@maxor.com</a>
    </Box> 
  )
};

export default PharmacyMapMessage;