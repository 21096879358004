import './styles/App.css';
import PharmacySearchPage from './pages/PharmacySearchPage';
import Header from './components/common/Header';
import { Container, ThemeProvider } from '@mui/material';
import theme from './styles/materialTheme';
import Footer from './components/common/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import initGA4 from './utils/google-analytics/ga4';

function App() {
  initGA4();

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Header />
        <Container maxWidth="xl" sx={{ minHeight: '85vh' }}>
          {/* <BrowserRouter basename='/'> */}
            <Routes>
              <Route path='/pharmacy-search/:carrier' element={<PharmacySearchPage />}></Route>
            </Routes>
          {/* </BrowserRouter> */}
        </Container>
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
