import { useEffect, useState } from 'react';
import { TextField, Button, Paper, FormControl, InputLabel, MenuItem, Select, Grid, Skeleton } from '@mui/material';
import { IPharmacySearchParameters } from '../../types/IPharmacySearchParameters';
import { useParams } from 'react-router-dom';
import { Plan, Plans } from '../../types/Plan';

interface PharmacySearchFormProps {
  onSearchClick: (params: IPharmacySearchParameters) => void;
}

const radiusOptions = [
  { value: '5', display: '5 miles' },
  { value: '10', display: '10 miles' },
  { value: '15', display: '15 miles' },
  { value: '20', display: '20 miles' },
  { value: '25', display: '25 miles' },
  { value: '30', display: '30 miles' },
]

const PharmacySearchForm = ( { onSearchClick } : PharmacySearchFormProps ) => {
  const [zipCode, setZipCode] = useState('');
  const [plan, setPlan] = useState('');
  const [radius, setRadius] = useState('5'); // default radius set to 5
  const [plans, setPlans] = useState<Plans>([]);
  const [dataLoading, setDataLoading] = useState(false);

  let { carrier } = useParams();

  useEffect(() => {
    const urlPrefix = process.env.REACT_APP_EXT_SVC_BASE_URL;
    // TODO should this call be moved into service/helper ??  
    setDataLoading(true);
    fetch(urlPrefix + '/GetGPPlanSettingByCarrier', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        AppToken: process.env.REACT_APP_APP_TOKEN,
        CarrierCode: carrier
      })
    }).then((response) => response.json())
      .then((data) => {
        console.log(data);
        if(data.length === 1 && data.StatusMessage === 'NO RESULTS FOUND') {
          // TODO do something - error message ...?
        } else {
          let index = 1;
          const tempPlans = data.map( (plan:Plan) => ({...plan, id:index++}) ); // adding index to each pharmacy
          setPlans(tempPlans);
          // setPlans(mock);
          if( tempPlans.length === 1 ) { // preselects the first plan if only one plan is available
            setPlan(tempPlans[0].SubscriberNum);
          }
          setDataLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const planSelectOptions = plans.map((plan: Plan) => (
    <MenuItem key={plan.PlanNum} value={plan.SubscriberNum}>
      {plan.DisplayPlanName}
    </MenuItem>
  ));

  const radiusSelectOptions = radiusOptions.map( r => (
    <MenuItem key={r.value} value={r.value}>
      {r.display}
    </MenuItem>
  ));

  const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.match(/[^0-9]/)) { // Check that input is numeric
      event.preventDefault();
    } else {
      setZipCode(event.target.value)
    }
  };

  // locally handles the search click before sending to parent
  const handleSearch = () => {
    const params: IPharmacySearchParameters = {
      zipCode: zipCode,
      plan: plan,
      radius: radius
    };
    // console.log("Search triggered in the PharmacySearchForm.. with parms: ", params);
    onSearchClick( params ); // use parent callback function to trigger actual search
  };

  return (
    <Paper sx={{p: 2, m: 1, pr:{ xs: 0, md: 2} }}>
      <Grid container spacing={2}>
        <Grid item 
          xs={12} md={4}
          sx={{
            bgcolor: 'primary.main',
            color: 'white',
            mb: {xs: '0', md: '-16px'},
            borderTopLeftRadius: '4px',
            borderTopRightRadius: {xs: '4px', md: '0'},
            borderBottomLeftRadius: {xs: '0', md: '4px'}
          }}
        >
          <h2 style={{margin: 0}}>Find a Pharmacy</h2>
          { carrier === 'montana' || carrier === 'tstmt' ? <p style={{margin: 0}}>Montana Residents</p> : null }
        </Grid>
        <Grid item
          xs={12} md={8}
          sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: { xs: 'column', md: 'row'},
              gap: { xs: '16px', md: 0}
            }}
          >
          { dataLoading ? (
            <>
            <Skeleton variant='rounded' sx={{mx: 2}} width={165} height={56}></Skeleton>
            <Skeleton variant='rounded' sx={{mx: 2}} width={110} height={56}></Skeleton>
            <Skeleton variant='rounded' sx={{mx: 2}} width={250} height={56}></Skeleton>
            <Skeleton variant='rounded' sx={{mx: 2}} width={95} height={56}></Skeleton>
            </>
          ) : (
            <>
            <TextField
              sx={{mx: 2, bgcolor: 'white'}}
              inputProps={{maxLength: 5}}
              label="Enter Zip Code"
              value={zipCode}
              onChange={handleZipChange}
            />
            <FormControl sx={{minWidth: '15%', textAlign: 'left'}}>
              <InputLabel id="radius-select-label" sx={{ml: 2}}>Select a Radius</InputLabel>
              <Select
                labelId="radius-select-label"
                id="radius-select"
                sx={{mx: 2, bgcolor: 'white'}}
                value={radius}
                label="Select a Radius"
                autoWidth
                onChange={(e) => setRadius(e.target.value)}
              >
                {radiusSelectOptions}
              </Select>
            </FormControl>
            <FormControl sx={{minWidth: '20%', textAlign: 'left'}}>
              <InputLabel id="plan-select-label" sx={{ml: 2}}>Select a Plan</InputLabel>
              <Select
                labelId="plan-select-label"
                id="plan-select"
                sx={{mx: 2, bgcolor: 'white'}}
                value={plan}
                label="Select a Plan"
                autoWidth
                onChange={(e) => setPlan(e.target.value)}
              >
                {planSelectOptions}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              onClick={ handleSearch }
              disabled={ zipCode === '' || plan === '' ? true : false }
              sx={{mx: 2, px: 3, py: 2}}
            >
              Search
            </Button>
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PharmacySearchForm;

const mock = [
  { id: 1, CarrierCode: "TEST-",
    DisplayPlanName: 'TEST',
    IsActive: true,
    PersonCode: '',
    PlanName: 'Montana Select',
    PlanNum: 0,
    SubscriberNum: 'MXRDEMO*TEST-',
    ExceptionInfo: '',
    StatusCode: 'SUCCESS',
    StatusMessage: ''
  },
  { id: 1, CarrierCode: "montana",
    DisplayPlanName: 'Montana Select',
    IsActive: true,
    PersonCode: '',
    PlanName: 'Montana Select',
    PlanNum: 1,
    SubscriberNum: 'TSTMT5678*TSTMT',
    ExceptionInfo: '',
    StatusCode: 'SUCCESS',
    StatusMessage: ''
  }
];