import { LatLngExpression } from "leaflet";
import { Pharmacies, Pharmacy } from "../../types/Pharmacy";
import { MapContainer, TileLayer } from 'react-leaflet';
import { useParams } from "react-router-dom";
import PharmacyMarker from "./PharmacyMarker";
import PharmacyMapMessage from "./PharmacyMapMessage";

interface PharmacyMapProps {
  pharmacies: Pharmacies;
  selectedPharmacy: number;
}

const PharmacyMap = ( { pharmacies, selectedPharmacy } : PharmacyMapProps ) => {
  let { carrier } = useParams();

  // TODO verify uisng the first pharmacy is a viable way to center the map
  const mapCenter: LatLngExpression = [pharmacies[0].PharmLatitude, pharmacies[0].PharmLongitude];

  const markerList = pharmacies.map( ( pharmacy : Pharmacy ) => 
    <PharmacyMarker
      pharmacy={pharmacy}
      key={pharmacy.Nabp}
      isSelected={pharmacy.id === selectedPharmacy}
    />
  );

  // TODO: should the zoom variable on Map be dynamic based on the search radius used??
  return (
    <div id="map">
      <MapContainer center={mapCenter} zoom={10} scrollWheelZoom={false}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        { markerList }
      </MapContainer>
      {carrier === 'montana' || carrier === 'tstmt' ? // TODO: update this logic if multiple carriers supported
        <PharmacyMapMessage /> : null
      }
    </div>
  );
}

export default PharmacyMap;
